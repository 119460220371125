import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Link,
  IconButton,
  LinearProgress,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Breadcrumbs
} from "@mui/material";
import bkstApi from "../../common/api";
import currency from "currency.js";
import ConfigContext from "../../common/context/ConfigContext";
import { useNavigate } from "react-router";
import ProductVariantSelection from "../component/ProductVariantSelection";
import ProductCustomization from "../component/ProductCustomization";
import { generateSizeSummary, getItemBasePrice, getItemMetadata, getItemUrl, getVariantTotal } from "../../common/util";
import ImageCarousel from "../../common/component/image/ImageCarousel";
import { AppStateContext } from "../../common/context/AppStateContext";
import ScheduleIcon from "@mui/icons-material/Schedule";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ProductDescription from "../component/ProductDescription";
import Images from "../../common/component/image/Images";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";

export default function ProductPage() {
  const siteConfig = useContext(ConfigContext);
  const { cart, updateCartState } = useContext(AppStateContext);

  let { productId, slug } = useParams();
  if (!productId && slug) {
    productId = slug.split("-").pop();
  }

  const navigate = useNavigate();

  const [product, setProduct] = useState("");

  const [loading, setLoading] = useState(true);

  const [quantity, setQuantity] = useState(1);

  const [variant, setVariant] = useState("");

  const [images, setImages] = useState([]);
  const [customization, setCustomization] = useState("");
  const [notes, setNotes] = useState("");

  const [variantPrice, setVariantPrice] = useState("");

  useEffect(() => {
    setLoading(true);
    bkstApi(`/site/${siteConfig.id}/product/${productId}`).then((res) => {
      setProduct(res.data.product);
      setDefaults(res.data.product);

      window.scrollTo(0, 0);
      setLoading(false);
    });
  }, [productId]);

  useEffect(() => {
    if (product) {
      setVariantPrice(getVariantTotal(variant, getItemBasePrice(variant)));
    }
  }, [variant, quantity]);

  const validateQuantity = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const handleQuantityChange = (value) => {
    const newValue = +quantity + +value;
    if (newValue > 0) {
      setQuantity(newValue);
    }
  };

  const setDefaults = (product) => {
    const defaultSize = product.options?.size?.sort((o1, o2) => +o1.pricing.amount - +o2.pricing.amount)[0];
    const defaultVariant = { size: defaultSize };

    if (product.options.size?.length === 1) {
      setVariant(defaultVariant);
    }

    setQuantity(1);
  };

  const addToCart = (e) => {
    e.preventDefault();

    let utm = "";
    const utmParams = sessionStorage.getItem("utm");
    if (utmParams) {
      utm = JSON.parse(utmParams);
    }

    const item = {
      type: "product",
      refId: product.id,
      product: {
        type: product.type,
        images: [product.images[0], ...images],
        title: product.title,
        description: notes,
        price: product.price,
        rules: product.rules
      },
      quantity,
      variant,
      customization
    };
    const cartId = cart?.id || "";

    bkstApi.post(`/site/${siteConfig.id}/cart`, { item, cartId, utm }).then((res) => {
      updateCartState(res.data.cartId, quantity);
      setDefaults(product);

      navigate(`/shop`);
    });
  };

  // SEO metadata generation
  const generateMetadata = () => {
    if (!product) return null;

    const metadata = getItemMetadata(product);
    const canonicalUrl = getItemUrl(product);
    console.log("canonicalUrl", canonicalUrl);

    const basePrice = currency(metadata.price.min).format();
    const maxPrice = metadata.price.max > metadata.price.min ? ` - ${currency(metadata.price.max).format()}` : "";

    return {
      title: `${product.title} | ${siteConfig.biz}`,
      description: `${product.description?.slice(0, 155)}... Starting at ${basePrice}${maxPrice}. ${
        metadata.dietary?.length ? `${metadata.dietary.join(", ")} options available.` : ""
      }`,
      schema: {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: product.title,
        image: product.images?.[0],
        description: product.description,
        offers: {
          "@type": "AggregateOffer",
          lowPrice: metadata.price.min,
          highPrice: metadata.price.max,
          priceCurrency: "USD",
          availability: product.status === "DRAFT" ? "https://schema.org/OutOfStock" : "https://schema.org/InStock"
        }
      }
    };
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    const metadata = getItemMetadata(product);
    const seoData = generateMetadata();

    const numVariantOptions = (product.options && Object.keys(product.options)?.length) || 0;
    const numSizeOptions = (product.options && product.options.size?.length) || 0;
    const numOptionalFields = (product.customization && Object.keys(product.customization)?.length) || 0;
    console.log("numSizeOptions", numSizeOptions);

    return (
      <>
        {seoData && (
          <Helmet>
            <title>{seoData.title}</title>
            <meta name="description" content={seoData.description} />

            {/* Open Graph tags */}
            <meta property="og:title" content={seoData.title} />
            <meta property="og:description" content={seoData.description} />
            <meta property="og:image" content={product.images?.[0]} />
            <meta property="og:type" content="product" />
            <meta property="og:url" content={window.location.href} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={seoData.title} />
            <meta name="twitter:description" content={seoData.description} />
            <meta name="twitter:image" content={product.images?.[0]} />

            {/* Schema.org JSON-LD */}
            <script type="application/ld+json">{JSON.stringify(seoData.schema)}</script>
          </Helmet>
        )}
        <Box>
          <Container maxWidth="lg">
            <Box pb={20}>
              <form onSubmit={addToCart}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Box pt={2} sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <Link
                        underline="hover"
                        sx={{ fontSize: "0.85rem", color: "#888", fontWeight: "600", cursor: "pointer" }}
                        color="inherit"
                        onClick={() => navigate(-1)}
                      >
                        {`< BACK`}
                      </Link>
                      <span style={{ fontSize: "0.75rem", color: "#888" }}>{` | `}</span>
                      <Breadcrumbs aria-label="breadcrumb" separator="›" sx={{ fontSize: "0.95rem", color: "#888" }}>
                        <Link size="small" color="inherit" component={RouterLink} to="/shop">
                          Online Shop
                        </Link>
                        <Link color="inherit" component={RouterLink} to={`/shop#${product.type.replace(/\s+/g, "-").toLowerCase()}`}>
                          {product.type}
                        </Link>
                      </Breadcrumbs>
                    </Box>
                  </Grid>
                  <Grid item md={7} sm={6} xs={12}>
                    <Box
                      sx={{
                        backgroundColor: "#ECECEC",
                        position: "-webkit-sticky",
                        position: "sticky",
                        top: "0"
                      }}
                    >
                      <ImageCarousel images={product.images} style={{ width: "100%", borderRadius: "4px" }} />
                    </Box>
                  </Grid>
                  <Grid item md={5} sm={6} xs={12}>
                    <Box>
                      <Box>
                        <Box mb={1}>
                          <Typography variant="h3" gutterBottom>
                            {product.title}
                          </Typography>
                        </Box>
                        <Box>
                          {metadata.leadTime > 0 && <Chip size="small" label={`Pre-order (min ${metadata.leadTime} hour notice)`} />}
                          {metadata.dietary?.length > 0 && <Chip size="small" label={`${metadata.dietary.join(", ")} options`} />}
                        </Box>
                        <Box my={1}>
                          <Typography variant="body1">
                            {currency(metadata.price.min).format()}{" "}
                            {metadata.price.max > metadata.price.min && `- ${currency(metadata.price.max).format()}`}
                          </Typography>
                        </Box>
                        <Box my={1}>
                          <ProductDescription
                            description={`${numSizeOptions === 1 ? `Size: ${generateSizeSummary(product.options.size[0])}. ` : ""}${
                              product.description
                            }`}
                          />
                        </Box>
                      </Box>
                      {(numVariantOptions > 1 || numOptionalFields > 0 || numSizeOptions > 1) && (
                        <Box>
                          <Typography variant="overline" color="textSecondary">
                            Select Options
                          </Typography>
                          <Paper elevation={0}>
                            <Box px={2} py={4}>
                              <Box>
                                <ProductVariantSelection variant={variant} options={product.options} onChange={(o) => setVariant(o)} />
                              </Box>
                              {product.customization?.inscription && (
                                <Box mt={3}>
                                  <ProductCustomization
                                    options={product.customization}
                                    customization={customization}
                                    onChange={(o) => setCustomization(o)}
                                  />
                                </Box>
                              )}
                              {product.customization?.notes && (
                                <Box mt={3}>
                                  <TextField
                                    id="notes"
                                    value={notes}
                                    label="Special Request (optional)"
                                    name="notes"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={Math.floor(notes.length / 40) + 1}
                                    onChange={(e) => setNotes(e.target.value)}
                                    helperText="At our discretion, we will try to accommodate your request on a best-effort basis."
                                  />
                                </Box>
                              )}
                              {product.customization?.photo && (
                                <Box mt={3}>
                                  <Box sx={{ border: "1px solid #ECECEC" }}>
                                    <Grid container spacing={2}>
                                      <Grid item sm={4} xs={12}>
                                        <Images pics={images} edit={true} onChange={setImages} folder={`img/r`} limit={1} />
                                      </Grid>
                                      <Grid item sm={8} xs={12}>
                                        <Box pt={2}>
                                          <Typography variant="caption" color="textSecondary">
                                            For best results, use high-resolution photos in JPEG or PNG format. We will crop the image to achieve the
                                            best possible fit, but we will not alter the design of the uploaded photos.
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Paper>
                        </Box>
                      )}
                      {product.status === "DRAFT" && (
                        <Box my={4}>
                          <Alert severity="error">Sorry, this item is no longer available!</Alert>
                        </Box>
                      )}
                      <Box
                        my={4}
                        sx={{
                          backgroundColor: "#ECECEC",
                          position: "-webkit-sticky",
                          position: "sticky",
                          bottom: "0",
                          opacity: "1",
                          zIndex: 1000,
                          borderRadius: "5px"
                        }}
                      >
                        <Box px={2} pb={2} pt={3} sx={{ display: "flex", gap: "10px" }}>
                          <Box>
                            <TextField
                              type="number"
                              name="quantity"
                              label="Quantity"
                              value={quantity}
                              variant="outlined"
                              id="quantity"
                              onChange={(e) => setQuantity(e.target.value)}
                              inputProps={{
                                sx: { textAlign: "center" }
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton onClick={() => handleQuantityChange(-1)} disabled={quantity === 1}>
                                      <RemoveIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => handleQuantityChange(1)}>
                                      <AddIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              sx={{
                                width: "125px",
                                "& .MuiOutlinedInput-root": {
                                  paddingRight: 0,
                                  paddingLeft: 0
                                },
                                backgroundColor: "#FFFFFF"
                              }}
                              onKeyPress={validateQuantity}
                            />
                          </Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <Button
                              fullWidth
                              size="large"
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={product.status === "DRAFT"}
                              sx={{ height: "56px" }}
                            >
                              {+variantPrice > 0 && `${currency(variantPrice * quantity).format()} | `} Add to Cart
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Container>
        </Box>
      </>
    );
  }
}
